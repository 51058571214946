
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import moment from 'moment';
import { VueCookieNext } from 'vue-cookie-next';
import { joinSpans } from '@fullcalendar/common';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'attendance_updateInfo_drawer',
  components: {
    Field,
  },
  data() {
    return {
      firsttimeLoad: false,
      bankType: false,
      moment: '' as any,
      api_url: '',
      masterTrainerList: [],
      associateTrainerList: [],
      guestTrainerList: [],
      formData: {
        id: '',
        lead_trainer: '' as any,
        associate_trainer: '',
        guest_trainer: '',
        training_date: '',
        attendance_date: '',
        start_time: '',
        end_time: '',
        batch_id: '',
        institute_id: '',
        course_id: '',
        tranche_id: '',
        entity_id: '',
        term_no: '',
        session_no: '',
      },
      loading: false,
      traineeList: [] as any,
      tempObject: {
        id: '',
        image: '',
        registration_number: '',
        name: '',
        is_present: true,
        is_late: false,
        attendance_date: '',
        in_time: '',
        out_time: '',
        remarks: '',
      },
      calendar_id: '' as any,
      is_attendance_given: 0,
      class_type: 1,
      url: '',
      load: false,
    };
  },
  async created() {
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
    this.load = true;

    this.emitter.on('attendance-update-drawer-info', async (data) => {
      this.class_type = data?.batch_info?.batch_info_details[0]?.class_type;
      if (data?.is_attendance_given == 1) {
        this.calendar_id = data.id;
        this.formData.id = data.id;
        this.is_attendance_given = data?.is_attendance_given;
        this.formData.lead_trainer =
          data?.training_calendar_details?.lead_trainer_id;
        this.formData.associate_trainer =
          data?.training_calendar_details?.associate_trainer_id;
        this.formData.guest_trainer =
          data?.training_calendar_details?.guest_trainer_info?.id;
        this.formData.training_date = data.training_date;
        this.formData.batch_id = data.batch_info_id;
        this.formData.course_id = data.course_info_id;
        this.formData.entity_id = data.entity_info_id;
        this.formData.institute_id = data.training_institute_id;
        this.formData.tranche_id = data.x_tranche_id;
        this.formData.term_no = data.term_no;
        this.formData.session_no = data.session_no;

        if (data?.training_calendar_details == null){
            this.formData.start_time = moment(data.start_time, 'hh:mm').format('LT');
            this.formData.end_time = moment(data.end_time, 'hh:mm').format('LT');
            this.formData.attendance_date = data?.training_date;
        }else{
            this.formData.start_time = moment(data?.training_calendar_details?.actual_start_time,'hh:mm').format('LT');
            this.formData.end_time = moment(data?.training_calendar_details?.actual_end_time,'hh:mm').format('LT');
            this.formData.attendance_date = data?.training_calendar_details?.actual_training_date;
        }

        //await this.getEnrolledEditTrainee(data);
        //await this.getTrainerDetails();
        await this.getTraineeList();
        await this.getTrainerList();
        await this.getGuestTrainer();
      } else {
        this.formData.lead_trainer = '';
        this.formData.associate_trainer = '';
        this.formData.id = data.id;
        this.formData.training_date = data.training_date;
        this.formData.attendance_date = data.training_date;
        this.formData.batch_id = data.batch_info_id;
        this.formData.course_id = data.course_info_id;
        this.formData.entity_id = data.entity_info_id;
        this.formData.institute_id = data.training_institute_id;
        this.formData.tranche_id = data.x_tranche_id;
        this.formData.term_no = data.term_no;
        if (data.term_no == 2) {
          this.formData.lead_trainer = '0';
        } else {
          this.formData.lead_trainer = '' as any;
        }

        this.formData.session_no = data.session_no;
        this.formData.start_time = moment(data.start_time, 'hh:mm').format('LT');
        this.formData.end_time = moment(data.end_time, 'hh:mm').format('LT');

        this.formData.lead_trainer =
          data?.batch_info?.batch_info_details[0]?.master_trainer_id;
        this.formData.associate_trainer =
          data?.batch_info?.batch_info_details[0]?.associate_trainer_id;

        this.formData.guest_trainer = '';
        //await this.getEnrolledTrainee();
        await this.getTraineeList();
        await this.getTrainerList();
        await this.getGuestTrainer();

        // this.getGuestTrainer();
      }
    });
    setTimeout(() => (this.load = false), 2000);
  },

  methods: {
    async confirmationBtn() {
      Swal.fire({
        text: "Please confirm that the Attendance information is correct. You'll not be able to update the attendance after submission",
        icon: 'warning',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-warning',
        },
      }).then(() => {
        this.formSubmit();
      });
    },
    async formSubmit() {
      // show a confirmation button

      this.loading = true;
      let form = new FormData();
      for (var key in this.formData) {
        form.set(key, this.formData[key]);
      }
      form.set('attendance_details', JSON.stringify(this.traineeList));
      let user_id = VueCookieNext.getCookie('_seip_user');
      form.set('user_id', user_id.id);
      if (
        this.formData.lead_trainer != '' ||
        this.formData.lead_trainer ||
        this.formData.lead_trainer === 0 ||
        this.formData.lead_trainer === '0'
      ) {
        ApiService.post(this.VUE_APP_TRAINEE_ATTENDANCE_SAVE_API, form)
          .then((response) => {
            this.loading = false;
            if (response?.data?.status == 'success') {
              Swal.fire({
                text: response.data.data,
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                DrawerComponent?.hideAll();
                this.emitter.emit('attendance-updated', true);
              });
            } else {
              let err = '';
              for (const field of Object.keys(response.data.errors)) {
                err += response.data.errors[field][0] + '<br>';
              }
              Swal.fire({
                title: response.data.errors,
                html: err,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            }
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Please Select Lead Trainer',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
        this.loading = false;
        this.load = false;
      }
    },

    // async getTrainerDetails() {
    //   this.load = true;
    //   ApiService.get(
    //     this.VUE_APP_TRAINER_DETAILS_BATCH_INFO +
    //       '?batch_id=' +
    //       this.formData.batch_id +
    //       '&term_no=' +
    //       this.formData.term_no
    //   )
    //     .then((response) => {
    //       this.masterTrainerList = response.data.data.master_trainer;
    //       this.associateTrainerList = response.data.data.associate_trainer;
    //       this.load = false;
    //     })
    //     .catch((response) => {
    //       console.log(response);
    //       this.load = false;
    //     });
    // },

    async getTrainerList() {
      this.load = true;
      await ApiService.get(
        this.VUE_APP_TRAINER_LIST_API +
          '?tranche_id=' +
          this.formData.tranche_id +
          '&entity_info_id=' +
          this.formData.entity_id +
          '&institute_info_id=' +
          this.formData.institute_id +
          '&course_info_id=' +
          this.formData.course_id +
          '&active_status=1'
      )
        .then((response) => {
          this.load = false;
          //this.trainerInfo = response.data.data;
          this.masterTrainerList = response.data.data;
          this.associateTrainerList = response.data.data;
        })
        .catch((response) => {
          this.load = false;
          console.log(response);
        });
    },

    async getGuestTrainer() {
      this.load = true;
      ApiService.get(
        this.VUE_APP_TRAINER_LIST_API +
          '?entity_info_id=' +
          this.formData.entity_id +
          '&tranche_id=' +
          this.formData.tranche_id +
          '&institute_info_id=' +
          this.formData.institute_id +
          '&course_info_id=' +
          this.formData.course_id +
          '&mapping_type=2&is_guest_trainer=1'
      )
        .then((response) => {
          this.guestTrainerList = response.data.data;
          this.load = false;
        })
        .catch((response) => {
          console.log(response);
          this.load = false;
        });
    },

    async getTraineeList() {
      ApiService.get(
        'attendance/trainee_list' +
          '?batch_info_id=' +
          this.formData.batch_id +
          '&course_info_id=' +
          this.formData.course_id +
          '&entity_id=' +
          this.formData.entity_id +
          '&training_institute_id=' +
          this.formData.institute_id +
          '&tranche_id=' +
          this.formData.tranche_id +
          '&ti_calendar_id=' +
          this.formData.id +
          '&is_attendance_given=' +
          this.is_attendance_given +
          '&term_no=' +
          this.formData.term_no
      )
        .then((response) => {
          this.traineeList = [];
          this.traineeList = response.data.data;
          this.load = false;
        })
        .catch((response) => {
          this.load = false;
          console.log(response);
        });
    },
    // async getEnrolledTrainee() {
    //   this.firsttimeLoad = true;

    //   ApiService.get(
    //     this.VUE_APP_ENROLLED_TRAINEE_LIST_API +
    //       '?batch_info_id=' +
    //       this.formData.batch_id +
    //       '&course_info_id=' +
    //       this.formData.course_id +
    //       '&entity_id=' +
    //       this.formData.entity_id +
    //       '&enrollment_status=1' +
    //       '&training_institute_id=' +
    //       this.formData.institute_id +
    //       '&tranche_id=' +
    //       this.formData.tranche_id
    //   )
    //     .then((response) => {
    //       this.traineeList = [];
    //       this.tempObject.is_late = false;
    //       this.tempObject.is_present = true;

    //       for (let i = 0; i < response.data.data.length; i++) {
    //         this.tempObject.id = response.data.data[i]?.id;
    //         this.tempObject.image =
    //           response.data.data[i]?.attach_file_info?.file_path;
    //         this.tempObject.registration_number =
    //           response.data.data[i].registration_number;
    //         this.tempObject.name = response.data.data[i]?.trainee_name;
    //         this.tempObject.attendance_date = this.formData?.training_date;
    //         this.tempObject.in_time = this.formData?.start_time;
    //         this.tempObject.out_time = this.formData?.end_time;
    //         this.tempObject.is_late = false;
    //         this.tempObject.is_present = true;
    //         this.traineeList.push(JSON.parse(JSON.stringify(this.tempObject)));
    //         setTimeout(() => (this.load = false), 2000);
    //       }
    //       this.firsttimeLoad = false;
    //     })
    //     .catch((response) => {
    //       this.load = false;
    //       console.log(response);
    //     });
    // },
    // async getEnrolledEditTrainee(data) {
    //   // console.log(moment(data?.details?.in_time[0], "hh").format("LT"));
    //   ApiService.get(
    //     this.VUE_APP_ENROLLED_TRAINEE_LIST_API +
    //       '?batch_info_id=' +
    //       this.formData.batch_id +
    //       '&course_info_id=' +
    //       this.formData.course_id +
    //       '&entity_id=' +
    //       this.formData.entity_id +
    //       '&enrollment_status=1' +
    //       '&training_institute_id=' +
    //       this.formData.institute_id +
    //       '&tranche_id=' +
    //       this.formData.tranche_id
    //   )
    //     .then((response) => {
    //       this.traineeList = [];

    //       this.tempObject.is_present = false;
    //       this.tempObject.is_late = false;
    //       for (let i = 0; i < response.data.data.length; i++) {
    //         for (let j = 0; j < data?.details?.attendance_details.length; j++) {
    //           this.tempObject.is_present = false;
    //           this.tempObject.is_late = false;
    //           if (
    //             data?.details?.attendance_details?.[j]?.trainee_profile_id ===
    //             response.data.data[i]?.id
    //           ) {
    //             this.tempObject.id = response.data.data[i]?.id;
    //             this.tempObject.image =
    //               response.data.data[i]?.attach_file_info?.file_path;
    //             this.tempObject.registration_number =
    //               response.data.data[i].registration_number;
    //             this.tempObject.name = response.data.data[i]?.trainee_name;
    //             this.tempObject.attendance_date =
    //               data?.details?.attendance_date[j];
    //             this.tempObject.in_time = moment(
    //               data?.details?.in_time[j],
    //               'hh:mm'
    //             ).format('LT');
    //             this.tempObject.out_time = moment(
    //               data?.details?.out_time[j],
    //               'hh:mm'
    //             ).format('LT');
    //             if (data?.details?.attendance_details?.[j]?.is_present == 1) {
    //               this.tempObject.is_present = true;
    //               this.traineeList.push(
    //                 JSON.parse(JSON.stringify(this.tempObject))
    //               );
    //               this.load = false;
    //             }
    //             if (
    //               data?.details?.attendance_details?.[j]?.is_present == 1 &&
    //               data?.details?.attendance_details?.[j]?.is_late == 1
    //             ) {
    //               this.tempObject.is_present = true;
    //               this.tempObject.is_late = true;
    //               this.traineeList.push(
    //                 JSON.parse(JSON.stringify(this.tempObject))
    //               );
    //               this.load = false;
    //             }
    //             if (
    //               data?.details?.attendance_details?.[j]?.is_present == 0 &&
    //               data?.details?.attendance_details?.[j]?.is_late == 0
    //             ) {
    //               this.tempObject.is_present = false;
    //               this.tempObject.is_late = false;
    //               this.traineeList.push(
    //                 JSON.parse(JSON.stringify(this.tempObject))
    //               );
    //               this.load = false;
    //             }
    //           }
    //           // if (
    //           //   data?.details?.late_trainee?.[i] === response.data.data[i]?.id
    //           // ) {
    //           //   this.tempObject.is_late = true;
    //           // }
    //           // if (
    //           //   data?.details?.present_trainee?.[i] === response.data.data[i]?.id
    //           // ) {
    //           //   this.tempObject.is_present = true;
    //           // }
    //           // if (
    //           //   data?.details?.absent_trainee?.[i] === response.data.data[i]?.id
    //           // ) {
    //           //   this.tempObject.is_present = false;
    //           //   this.tempObject.is_late = false;
    //           // }

    //           //get attendance status
    //         }
    //       }
    //     })
    //     .catch((response) => {
    //       this.load = false;
    //       console.log(response);
    //     });
    // },
    // async getAttendanceInfo() {
    //   this.load = true;
    //   ApiService.get(
    //     this.VUE_APP_ATTENDANCE_TRAINEE_LIST_API +
    //       '?batch_info_id=' +
    //       this.formData.batch_id +
    //       '&course_info_id=' +
    //       this.formData.course_id +
    //       '&entity_id=' +
    //       this.formData.entity_id +
    //       '&enrollment_status=1' +
    //       '&training_institute_id=' +
    //       this.formData.institute_id +
    //       '&tranche_id=' +
    //       this.formData.tranche_id
    //   )
    //     .then((response) => {
    //       this.traineeList = [];
    //       for (let i = 0; i < response.data.data.length; i++) {
    //         this.tempObject.id = response.data.data[i]?.id;
    //         this.tempObject.image =
    //           response.data.data[i]?.attach_file_info?.file_path;
    //         this.tempObject.registration_number =
    //           response.data.data[i].registration_number;
    //         this.tempObject.name = response.data.data[i]?.trainee_name;

    //         // this.tempObject.in_time = this.formData?.start_time;
    //         // this.tempObject.out_time = this.formData?.end_time;
    //         // this.tempObject.is_present = true;
    //         this.traineeList.push(JSON.parse(JSON.stringify(this.tempObject)));
    //         this.load = false;
    //       }
    //     })
    //     .catch((response) => {
    //       this.load = false;
    //       console.log(response);
    //     });
    // },
    setAttendanceDate(attendance_date) {
      for (let i = 0; i < this.traineeList.length; i++) {
        this.traineeList[i].attendance_date = attendance_date;
      }
    },
    setInTime(startTime) {
      for (let i = 0; i < this.traineeList.length; i++) {
        this.traineeList[i].in_time = startTime;
      }
    },
    setOutTime(endTime) {
      for (let i = 0; i < this.traineeList.length; i++) {
        this.traineeList[i].out_time = endTime;
      }
    },
  },
});
